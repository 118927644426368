import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Icon,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { FaTrashAlt } from "react-icons/fa";
import React from "react";
import { FiRefreshCcw } from "react-icons/fi";

function AccountsTableRow(props) {
  const { name, email, company, status, isLast, role, tempPass } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr className="row">
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {company}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Badge
          bg={status === "inactive" ? "orange.400" : "green.400"}
          color={status === "ok" ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {role}
          </Text>
        </Flex>
      </Td>
      {status == "inactive" ? (
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Badge
            bg={status === "inactive" ? "orange.400" : "green.400"}
            color={status === "ok" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {tempPass}
          </Badge>
        </Td>
      ) : (
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        ></Td>
      )}

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          align="flex-start"
          p={{ md: "24px" }}
        >
       {/*    <Button p="0px" bg="transparent" variant="no-effects">
            <Flex color={textColor} cursor="pointer" align="center" p="12px">
              <Icon as={FiRefreshCcw} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                RESET PASSWORD
              </Text>
            </Flex>
          </Button> */}

          <Button
            p="0px"
            bg="transparent"
            variant="no-effects"
            mb={{ sm: "10px", md: "0px" }}
            me={{ md: "12px" }}
          >
            <Flex color="red.500" cursor="pointer" align="center" p="12px">
              <Icon as={FaTrashAlt} me="4px" />
              <Text fontSize="sm" fontWeight="semibold">
                DELETE
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default AccountsTableRow;
