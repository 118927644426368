import { Box, Button, ButtonGroup, Stack } from "@chakra-ui/react";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPageButtons = () => {
    const buttons = [];

    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          size="sm"
          onClick={() => handlePageChange(i)}
          colorScheme={currentPage === i ? "blue" : "gray"}
        >
          {i}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <Box display={"flex"} justifyContent={"center"} pb={"24px"}>
      <Stack direction="row" spacing={2} align="center">
        <ButtonGroup>
          <Button
            size="sm"
            disabled={isFirstPage}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          {renderPageButtons()}
          <Button
            size="sm"
            disabled={isLastPage}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </Stack>
    </Box>
  );
}
