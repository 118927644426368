import {
  Table,
  Tbody,
  Text,
  Th,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Thead,
  Flex,
  Button,
  Badge,
  Tr,
  Td,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { EyeIcon } from "components/Icons/Icons";
import React, { useState, useEffect } from "react";
import { parseMultilingualText } from "utils";

export default function ScanModal(props) {
  const { isOpen, onClose, data } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  /*   const fetchScans = async function () {
    //setScans(props.data);
    await axios
      .get(`/scan?all=true&companyid=${company}`)
      .then((res) => {
        var result = [];
        for (let i = 0; i < res.data.length; i++) {
          var data = flattenObject(res.data[i]);
          result.push(data);
        }
        const cityScans = [...new Set(result.flat())].filter(
          (item) => item.location.city === selectedCity
        );
        setScans(cityScans);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }; */

  /*  useEffect(() => {
    fetchScans();
  }, [company, selectedCity]); */

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scanned Tags</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0} overflowX={{ sm: "scroll" }}>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.500">
                  Tag
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Product
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Country
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  City
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Date
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Flag
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index, arr) => {
                return (
                  <Tr>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" color={textColor} fontWeight="bold">
                          {row.tagId}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {parseMultilingualText(
                            row.productName != undefined
                              ? row.productName
                              : row.productId,
                            "en"
                          )}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.distributorName != undefined
                            ? row.distributorName
                            : row.distributorId}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.location.country}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.location.city}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {new Date(row.createdAt).toUTCString()}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Badge
                        bg={
                          row.flag == 0 || row.flag == undefined
                            ? "#fe4f10"
                            : row.flag == 2
                            ? "gray"
                            : "yellow"
                        }
                        color={status === "ok" ? "white" : "white"}
                        padding={"5px"}
                        height={"30px"}
                        marginBottom={"5px"}
                        width={"30px"}
                        borderRadius="8px"
                      ></Badge>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
