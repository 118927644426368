// Chakra imports
import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";

import axios from "axios";
import AccountsTableRow from "components/Tables/AccountsTable";
import NewUserModal from "components/modals/newUserModal";
import LoadingIcon from "components/Icons/LoadingIcon";

function Companies() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const fetchUsers = async function () {
    setIsLoading(true);
    let data = await axios
      .get("/users")
      .then((response) => {
        setUsers(response.data);
        setIsLoading(false);
        return response.data;
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the accounts",
        });
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px" bg={"#D6E6F6"}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Accounts
          </Text>
        </CardHeader>
        <CardBody>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => fetchUsers()} style={{ margin: "5px" }}>
              <FiRefreshCw />
            </Button>
            <Button onClick={onOpen}>New Account</Button>
          </div>
          <NewUserModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </CardBody>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th pl="0px" borderColor={borderColor} color="gray.600">
                  Name
                </Th>
                <Th borderColor={borderColor} color="gray.600">
                  Email
                </Th>
                <Th borderColor={borderColor} color="gray.600">
                  Company
                </Th>
                <Th borderColor={borderColor} color="gray.600">
                  Status
                </Th>
                <Th borderColor={borderColor}>Role</Th>
                <Th borderColor={borderColor}>Temporary Password</Th>
                <Th borderColor={borderColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <div
                  style={{
                    padding: "15px",
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <LoadingIcon />
                  <Text>Fetching users...</Text>
                </div>
              )}
              {users.length > 0 &&
                users.map((row, index, arr) => {
                  return (
                    <AccountsTableRow
                      name={row.name}
                      email={row.email}
                      company={row.company.id}
                      date={row.createdAt}
                      status={row.status}
                      role={row.role}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                      tempPass={row.tempPassword}
                    />
                  );
                })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Companies;
