import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { lambdaBaseURI } from "variables/general";
import { useToast } from "@chakra-ui/react";

const AuthContext = createContext({});

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [company, setCompany] = useState();
  const [role, setRole] = useState("admin");
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [companyName, setCompanyName] = useState();

  const history = useHistory();
  const location = useLocation();

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  useEffect(() => {
   
    setUser(window.localStorage.getItem("troof_token"));
  }, []);

  useEffect(() => {
 
      const token = window.localStorage.getItem("troof_token");
      if (token) {
        // Fetch user data with the token
        axios
          .get('/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setCompany(res.data.company.id);
            setCompanyName(res.data.company.name);
            setRole(res.data.role);
            setUserName(res.data.fullName);
            setEmail(res.data.email);
          })
          .catch((error) => {
            setError(error.message);
          })
          .finally(() => setLoadingInitial(false));
      } else {
        setLoadingInitial(false);
      }

  
  }, [loadingInitial]);

  function login(email, password) {
    setLoading(true);

    axios
      .post("/login", {
        email: email,
        password: password,
        role: "admin",
      })
      .then((response) => {
        setUser(response.data.token);
        window.localStorage.setItem("troof_token", response.data.token);
     /*    setCompany("A002");
        setCompanyName("Evaflore");
        setRole("admin");
        setUserName(response.data.fullName);
        setEmail(email); */
        if (response.data.token){
        axios
        .get('/me', {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        })
        .then((res) => {
          setCompany(res.data.company?.id);
          setCompanyName(res.data.company.name);
          setRole(res.data.role);
          setUserName(res.data.fullName);
          setEmail(res.data.email);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => setLoadingInitial(false));
      }
        history.push("/admin/dashboard");
      })
      .catch((error) => {
        showToast({
          status: "info",
          title: "Auth",
          description: error.message,
        });
      })
      .finally(() => setLoading(false));
  }

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      role,
      company,
      userName,
      email,
      error,
      login,
      companyName,
    }),
    [user, loading, error, userName, company, email, companyName, role]
  );

 

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
