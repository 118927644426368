// Chakra imports
import {
  Alert,
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  Select,
  SimpleGrid,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";

import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";

import React, { useState, useEffect } from "react";

// Variables
import { barChartOptions, lineChartOptions, months } from "variables/charts";
import axios from "axios";
import { getMonthlyOrders } from "variables/charts";
import { getTotalScansByCountry } from "variables/charts";

import { getDailyScans, getProductScans } from "variables/charts";
import { NavLink } from "react-router-dom";

import { AiOutlineGlobal, AiOutlineScan } from "react-icons/ai";
import { SiNfc } from "react-icons/si";
import {
  MdMilitaryTech,
  MdPeopleOutline,
  MdRemoveRedEye,
} from "react-icons/md";
import useAuth from "views/Pages/useAuth";
import { filterDataByTimeRange } from "variables/charts";
import Scans from "./Scans";
import ScansDashboard from "./ScansDashboard";
import { countriesCoordinates } from "variables/map";
import { parseMultilingualText } from "utils";

export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const [scans, setScans] = useState([]);
  const { colorMode } = useColorMode();
  const [countries, setCountries] = useState(
    countriesCoordinates.map((item) => item.name)
  );
  const [totalScans, setTotalScans] = useState();
  const [totalCountries, setTotalCountries] = useState();
  const [dailyScans, setDailyScans] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [timeRange, setTimeRange] = useState("today");
  const [distributorFilter, setDistributorFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [countryStats, setCountryStats] = useState([]);
  const [troofTags, setTroofTags] = useState(0);
  const [susTags, setSusTags] = useState(0);
  const [greyTags, setGreyTags] = useState(0);
  const [countUniqueProductScans, setCountUniqueProductScans] = useState(0);
  const { company } = useAuth();

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getScansCompany = async () => {
    var url = "/scan?filter=true&companyid=A002";
    if (timeRange != "") {
      url += "&time=" + timeRange;
    }
    if (countryFilter != "") {
      url += "&country=" + countryFilter;
    }
    if (distributorFilter != "") {
      url += "&distributor=" + distributorFilter;
    }
    if (productFilter != "") {
      url += "&product=" + productFilter;
    }
    return axios
      .get(url)
      .then((res) => {
        let scansByCountry = getTotalScansByCountry(res.data);
        let temp = [];
        let totalScanned = 0;
        let totalCountriesTemp = 0;
        Object.keys(scansByCountry).forEach(function (key, index) {
          temp.push({ country: key, scans: scansByCountry[key] });
          totalScanned += scansByCountry[key];
          totalCountriesTemp += 1;
        });
        setCountryStats(temp);

        setTotalScans(totalScanned);
        setTotalCountries(totalCountriesTemp);
        setSusTags(res.data.filter((x) => x.flag == 1).length);
        setGreyTags(res.data.filter((x) => x.flag == 2).length);
        const uniqueProductIds = new Set();
        res.data.forEach((item) => {
          if (item.productId) {
            uniqueProductIds.add(item.productId);
          }
        });
        setCountUniqueProductScans(uniqueProductIds.size);
        let daily = getDailyScans(res.data);
        setDailyScans(daily);
        setScans(res.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the scans",
        });
      });
  };

  const getProductsCompany = async () => {
    return axios
      .get(`/product?companyId=${company}&all=true`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the products",
        });
      });
  };
  /*   const getTagsCompanyCount = async () => {
    return axios
      .get(`/tag?companyId=${company}&count=true`)
      .then((res) => {
        setTroofTags(res.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the tags",
        });
      });
  }; */

  const getDistributorCompany = async () => {
    return axios
      .get(`/distributor?companyId=${company}`)
      .then((response) => {
        setDistributors(response.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the distributors",
        });
      });
  };

  const fetchData = async () => {
    setIsLoading(true);
    await Promise.all([
      getScansCompany(),
      getProductsCompany(),
      //getTagsCompanyCount(),
      getDistributorCompany(),
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!company) return;

    fetchData();
  }, [company]);

  useEffect(() => {
    if (!company) return;
    getScansCompany();
  }, [
    distributorFilter,
    countryFilter,
    timeRange,
    productFilter,
    productFilter,
  ]);

  return (
    <>
      {loading == false ? (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
          <SimpleGrid
            columns={{ sm: 1, md: 2, xl: 4 }}
            spacing="24px"
            mb="20px"
          >
            <Card minH="125px">
              <Flex direction="column">
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                  mb="25px"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Unique Products
                    </StatLabel>
                    <Flex>
                      <StatNumber
                        fontSize="xl"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {countUniqueProductScans}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius="50%"
                    as="box"
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}
                  >
                    <AiOutlineScan size={30} style={{ color: "white" }} />
                  </IconBox>
                </Flex>
              </Flex>
            </Card>
            <Card minH="125px">
              <Flex direction="column">
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                  mb="25px"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Suspicious
                    </StatLabel>
                    <Flex>
                      <StatNumber
                        fontSize="xl"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {susTags}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <Stat me="auto">
                    <StatLabel
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Grey Market
                    </StatLabel>
                    <Flex>
                      <StatNumber
                        fontSize="xl"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {greyTags}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius="50%"
                    as="box"
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}
                  >
                    <MdRemoveRedEye size={30} color={iconBoxInside} />
                  </IconBox>
                </Flex>
              </Flex>
            </Card>
            <Card minH="125px">
              <Flex direction="column">
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                  mb="25px"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Total Scans
                    </StatLabel>
                    <Flex>
                      <StatNumber
                        fontSize="xl"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {scans.length}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius="50%"
                    as="box"
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}
                  >
                    <SiNfc style={{ color: "white" }} size={26} />
                  </IconBox>
                </Flex>
              </Flex>
            </Card>
            <Card minH="125px">
              <Flex direction="column">
                <Flex
                  flexDirection="row"
                  align="center"
                  justify="center"
                  w="100%"
                  mb="25px"
                >
                  <Stat me="auto">
                    <StatLabel
                      fontSize="xs"
                      color="gray.400"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      Total Countries
                    </StatLabel>
                    <Flex>
                      <StatNumber
                        fontSize="xl"
                        color={textColor}
                        fontWeight="bold"
                      >
                        {totalCountries}
                      </StatNumber>
                    </Flex>
                  </Stat>
                  <IconBox
                    borderRadius="50%"
                    as="box"
                    h={"45px"}
                    w={"45px"}
                    bg={iconBlue}
                  >
                    <AiOutlineGlobal size={30} color={iconBoxInside} />
                  </IconBox>
                </Flex>
                {/*  <Text color="gray.400" fontSize="sm">
              <Text as="span" color="green.400" fontWeight="bold">
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
              </Flex>
            </Card>
          </SimpleGrid>
          <Card
            p="0px"
            maxW={{ sm: "100%", md: "100%" }}
            style={{ marginBottom: "20px" }}
          >
            <Box>
              <Flex
                direction="row"
                style={{ justifyContent: "start", alignItems: "center" }}
                p="28px 0px 0px 22px"
              >
                <Flex direction="column" p="0px 0px 28px 22px" width={"10%"}>
                  <Text>Filter by: </Text>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={distributorFilter}
                    onChange={(e) => setDistributorFilter(e.target.value)}
                  >
                    <option value="" selected>
                      Distributor
                    </option>
                    {distributors.map((dist, idx) => (
                      <option key={idx} value={dist.distributorId}>
                        {dist?.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={productFilter}
                    onChange={(e) => setProductFilter(e.target.value)}
                  >
                    <option value="">Product</option>
                    {products.map((product, idx) => (
                      <option key={idx} value={product.productId}>
                        { parseMultilingualText(product.name )}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={countryFilter}
                    onChange={(e) => setCountryFilter(e.target.value)}
                  >
                    <option value="">Country</option>
                    {countries.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="currentWeek">Current Week</option>
                    <option value="currentMonth">Current Month</option>
                    <option value="currentTrimester">Current Trimester</option>
                    <option value="currentYear">Current Year</option>
                    <option value="lastYear">Last Year</option>
                  </Select>
                </Flex>
              </Flex>
            </Box>
          </Card>
          <Grid
            templateColumns={{ sm: "1fr 1fr", lg: "2fr 2fr" }}
            templateRows={{ lg: "repeat(2, auto)" }}
            gap="20px"
          >
            <Card
              bg={
                colorMode === "dark"
                  ? "navy.800"
                  : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
              }
              p="0px"
              maxW={{ sm: "100%", md: "100%" }}
              gridColumn="1 / span 2"
            >
              <Box minH="300px">
                <LineChart
                  data={scans}
                  chartOptions={lineChartOptions}
                  company={company}
                  timeRange={timeRange}
                  country={countryFilter}
                  product={productFilter}
                />
              </Box>
            </Card>

            <Card
              p="0px"
              maxW={{ sm: "100%", md: "100%" }}
              gridColumn="1 / span 2"
            >
              <ScansDashboard
                timeRange={timeRange}
                product={productFilter}
                distributor={distributorFilter}
                country={countryFilter}
              />
            </Card>
            <div>
              <Card p="0px" maxW={{ sm: "100%", md: "100%" }}>
                <Flex direction="column">
                  <Flex align="center" justify="space-between" p="22px">
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                      Scanned products
                    </Text>
                    <NavLink to="/admin/scans">
                      <Button variant="primary" maxH="30px">
                        SEE ALL
                      </Button>
                    </NavLink>
                  </Flex>
                  <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                    <Table>
                      <Thead>
                        <Tr bg={tableRowColor}>
                          <Th color="gray.400" borderColor={borderColor}>
                            Product
                          </Th>
                          <Th color="gray.400" borderColor={borderColor}>
                            Total Scans
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {products.map((el, index, arr) => {
                          if (
                            scans.filter((i) => i.productId == el.productId)
                              .length > 0
                          ) {
                            return (
                              <Tr key={index}>
                                <Td
                                  color={textTableColor}
                                  fontSize="sm"
                                  fontWeight="bold"
                                  border={
                                    index === arr.length - 1 ? "none" : null
                                  }
                                >
                                  { parseMultilingualText( el.name)}
                                </Td>
                                <Td
                                  color={textTableColor}
                                  fontSize="sm"
                                  border={
                                    index === arr.length - 1 ? "none" : null
                                  }
                                >
                                  {`${parseInt(
                                    
                                      scans.filter(
                                        (i) => i.productId == el.productId
                                      ).length
                                  )}`}
                                </Td>
                              </Tr>
                            );
                          }
                        })}
                      </Tbody>
                    </Table>
                  </Box>
                </Flex>
              </Card>
            </div>
            <Card p="0px" maxW={{ sm: "100%", md: "100%" }}>
              <Flex direction="column">
                <Flex align="center" justify="space-between" p="22px">
                  <Text
                    fontSize="lg"
                    color={textColor}
                    fontWeight="bold"
                    marginRight="16px"
                  >
                    Geographical Distribution of Scanned Products
                  </Text>
                  <NavLink to="/admin/scans">
                    <Button variant="primary" maxH="30px">
                      SEE ALL
                    </Button>
                  </NavLink>
                </Flex>
              </Flex>
              <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                <Table>
                  <Thead>
                    <Tr bg={tableRowColor}>
                      <Th color="gray.400" borderColor={borderColor}>
                        Country
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        Market share
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {countries.length == 0 && (
                      <div
                        style={{
                          padding: "10px",
                          alignItems: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Text>No data available yet</Text>
                      </div>
                    )}
                    {countryStats.map((el, index, arr) => {
                      return (
                        <Tr key={index}>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            fontWeight="bold"
                            borderColor={borderColor}
                            border={index === arr.length - 1 ? "none" : null}
                          >
                            {el.country}
                          </Td>

                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            borderColor={borderColor}
                            border={index === arr.length - 1 ? "none" : null}
                          >
                            <Flex align="center">
                              <Text
                                color={textTableColor}
                                fontWeight="bold"
                                fontSize="sm"
                                me="12px"
                              >
                                {`${parseFloat(
                                  (parseFloat(el.scans) * 100) /
                                    parseFloat(totalScans)
                                ).toFixed(2)}%`}
                              </Text>
                              <Progress
                                size="xs"
                                //colorScheme={el.color}
                                value={parseInt(
                                  (parseInt(el.scans) * 100) /
                                    parseInt(totalScans)
                                )}
                                minW="120px"
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              {/*       <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                <Table>
                  <Thead>
                    <Tr bg={tableRowColor}>
                      <Th color="gray.400" borderColor={borderColor}>
                        Distributor
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        {timeRange} Active tags scanned
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        Unscanned products
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {companies.length == 0 && (
                      <div
                        style={{
                          padding: "10px",
                          alignItems: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Text>No data available yet</Text>
                      </div>
                    )}
                    {companies.map((el, index, arr) => {
                      return (
                        <Tr key={index}>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            fontWeight="bold"
                            borderColor={borderColor}
                            borderBottom={
                              index === arr.length - 1 ? "none" : null
                            }
                          >
                            {el.name}
                          </Td>

                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            borderColor={borderColor}
                            borderBottom={
                              index === arr.length - 1 ? "none" : null
                            }
                          >
                            <Flex align="center">
                              <Text
                                color={textTableColor}
                                fontWeight="bold"
                                fontSize="sm"
                                me="12px"
                              >
                                {el.scans}
                              </Text>
                              <Progress
                                size="xs"
                                //colorScheme={el.color}
                                value={parseInt(
                                  (parseInt(el.scans) * 100) /
                                  parseInt(totalScans)
                                )}
                                minW="120px"
                              /> 
                            </Flex>
                          </Td>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            fontWeight="bold"
                            borderColor={borderColor}
                            borderBottom={
                              index === arr.length - 1 ? "none" : null
                            }
                          >
                            {el.scans}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box> */}
            </Card>
          </Grid>
        </Flex>
      ) : (
        <Flex
          direction={"center"}
          style={{
            display: "center",
            align: "center",
            justify: "center",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30%",
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text style={{ margin: "5px" }}>Loading Dashboard...</Text>
        </Flex>
      )}
    </>
  );
}
