// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./map.css";
import useAuth from "views/Pages/useAuth";
import AnomaliesTable from "components/Tables/AnomaliesTable";
import AnomalyModal from "components/modals/anomalyModal";
import Pagination from "components/Tables/Pagination";
import LoadingIcon from "components/Icons/LoadingIcon";

function Anomalies() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [scans, setScans] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { company } = useAuth();
  const [selectedAnomaly, setSelectedAnomaly] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalPages = 5;

  const toast = useToast();

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchScans();
  };

  const selectAnomaly = (anomaly) => {
    setSelectedAnomaly(anomaly);
    onOpen();
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const fetchScans = async () => {
    setIsLoading(true);
    axios
      .get(`/scan?anomalies=true&companyId=${company}`)
      .then((res) => {
        setScans(
          res.data
        );
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (company) {
      fetchScans();
    }
  }, [company]);

  return (
    <Flex direction="column" gap={8} pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Suspicious scans
          </Text>
        </CardHeader>
        <CardBody>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.500">
                  Product ID
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Tag
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Status
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.500"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                scans.map((row, index, arr) => {
                  return (
                    <AnomaliesTable
                      logo={""}
                      id={row.productId}
                      country={row.location}
                      city={row.location}
                      status={"Scanned different city/country"}
                      key={index}
                      isLast={index === arr.length - 1}
                      date={row.createdAt}
                      tag={row.tagId}
                      ip={row.ipAddress}
                      flag={row.flag}
                      distributor={
                        row.distributorId == undefined
                          ? "unknown"
                          : row.distributorId
                      }
                      selectAnomaly={selectAnomaly}
                      company={row.companyId}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching scans...</Text>
            </div>
          )}
         {/*  <Pagination
            currentPage={currentPage}
            totalPages={scans.length / 5 + 1}
            onPageChange={handlePageChange}
          /> */}
        </CardBody>
      </Card>
      <AnomalyModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        anomaly={selectedAnomaly}
      />
    </Flex>
  );
}

export default Anomalies;
